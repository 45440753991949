import React from "react"

import Layout from "../components/layout"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} title="404: Nicht gefunden">
      <h1>404: Nicht gefunden</h1>
      <p>Tut uns leid, doch diese Seite existiert nicht (mehr).</p>
    </Layout>
  )
}

export default NotFoundPage